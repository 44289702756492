import {
  AudioGroups,
  AudioNames
} from '@/app/types'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'
import {
  blurState,
  instructionsState,
  startPhaseState,
  tableState,
  tournamentState,
  trainingResultsState,
  uiState
} from '@/stores'
import {
  CustomEvents,
  translations,
  CameraStates,
  game,
  modes,
  playersManager,
  trainingManager,
  hints,
  audioManager,
  requestManager,
  cameraManager,
  corePhasesManager
} from '@powerplay/core-minigames'
import {
  loadingCircleState,
  loadingState,
  translations as t,
  tutorialCoreState
} from '@powerplay/core-minigames-ui'
import { translatesReplacements } from '@/app/config'
import { stateManager } from '@/app/StateManager'
import { disciplinePhasesManager } from '@/app/phases'
import { endManager } from '@/app/EndManager'
import { tableStandingsHelper } from './TableStandingsHelper'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const initiateListeners = (vm: any) => {

  // Listener for language download
  window.addEventListener(CustomEvents.translations, () => {

    if (Number(import.meta.env.VITE_APP_LOCAL)) {

      translations.setTranslations(t, translatesReplacements)

    }
    vm.$i18n.setLocaleMessage('lang', translations.getTranslations())

    // musime zmenit hint v loadingu
    hints.setSpecificHintsCountForDiscipline(2)
    const hint = hints.getHintText(requestManager.disciplineID)

    loadingState().$patch({
      showLoading: true,
      hintText: vm.$t(hint)
    })

  })

  // Listener for game start event
  window.addEventListener(CustomEvents.gameStart, () => {

    if (!modes.isTutorial()) {

      loadingState().showLoading = false

    }

  })

  // Listener for game start instruction phase
  window.addEventListener(CustomEvents.startInstructionsPhase, () => {

    if (modes.isTutorial()) return

    instructionsState().$patch({
      showInstructions: true,
      showButton: false
    })
    cameraManager.setState(CameraStates.table)
    cameraManager.playTween(true)
    game.renderScene()

  })

  // Listener for game intro start event
  window.addEventListener(CustomEvents.startIntroPhase, () => {

    audioManager.play(AudioNames.audienceNoise, undefined, undefined, 1)
    if (modes.isTutorial()) return

    tableState().$patch({
      competitionText: modes.isTrainingMode() ?
        vm.$t('level').replace('%s', trainingManager.level) :
        `tableText${modes.mode}`,
      showTable: true
    })

    if (!modes.isTutorial() && !modes.isTrainingMode()) {

      audioManager.play(AudioNames.commentIntro)

    }

  })

  // Listener pre tutorial Typewrite
  window.addEventListener(CustomEvents.typeWrite, () => {

    if (!modes.isTutorial()) return

    tutorialCoreState().typeWriter = false

  })

  // Listener pre tutorial game event
  window.addEventListener(CustomEvents.tutorialGameEvent, () => {

    if (!modes.isTutorial()) return

    tutorialFlow.checkInput()

  })

  // Listener for game start listing phase
  window.addEventListener(CustomEvents.startStartListPhase, () => {

    if (modes.isTutorial()) return

    blurState().$patch({
      isActive: true,
      isTable: true
    })

    const data = playersManager.getDataForTable()
    console.log(data)
    tableState().$patch({
      dataTable: data,
      resultText: 'startList',
      isStartList: true,
      activeState: true
    })

  })

  // Listener for game start discipline phase
  window.addEventListener(CustomEvents.startDisciplinePhase, () => {

    if (modes.isTutorial()) return

    blurState().$patch({
      isActive: false,
      isTable: false
    })

    tableState().$patch({
      showTable: false,
      activeState: false,
      isStartList: false
    })

    // v turnajoch si musime vynulovat vysledky pre hraca, lebo mohol nejake uz mat, ale iba na zaciatku
    if (modes.isTournament() && playersManager.players[0].resultsArr && endManager.resultsCount === 0) {

      for (let i = 0; i < 2; i += 1) {

        playersManager.players[0].resultsArr[i] = {
          main: 0,
          second: 0
        }

      }

    }

  })

  // Listener for game start provisional results phase
  window.addEventListener(CustomEvents.startProvisionalResultsPhase, () => {

    if (modes.isTutorial()) return
    audioManager.stopAllAudio()
    blurState().$patch({
      isActive: true,
      isTable: true
    })

    if (corePhasesManager.disciplineActualAttempt < 1) stateManager.editTournamentDefaultValuesFromServer()

    playersManager.setStandings(
      2,
      modes.isTournament() && corePhasesManager.disciplineActualAttempt < 1,
      modes.isTournament() && corePhasesManager.disciplineActualAttempt < 1
    )

    const dataStandings = tableStandingsHelper.getDataForTable()
    // musime si zaznacit najlepsi vysledok, aby sme to potom mohli zobrazit
    tournamentState().bestResult = dataStandings[0].result ?? '-'
    if (tournamentState().bestResult === '') tournamentState().bestResult = '-'

    tableState().$patch({
      dataTable: dataStandings,
      resultText: 'provisionalResults',
      showTable: true,
      activeState: true
    })

    uiState().showFinishTopBox = false

    console.log(audioManager)
    audioManager.play(AudioNames.audienceNoise, undefined, undefined, 1)

  })

  // Listener for game start final standings phase
  window.addEventListener(CustomEvents.startFinalStandingsPhase, () => {

    if (modes.isTutorial()) return

    if (!audioManager.isAudioGroupPlaying(AudioGroups.commentators)) {

      audioManager.play(AudioNames.commentFinalResults)

    }

    audioManager.play(AudioNames.audienceNoise, undefined, undefined, 1)

    uiState().showFinishTopBox = false

    if (modes.isTournament() && !disciplinePhasesManager.tournamentDataSet) return

    blurState().$patch({
      isActive: true,
      isTable: true
    })

    if (modes.isTournament()) {

      stateManager.editTournamentDefaultValuesFromServer()
      playersManager.setStandings()

    }

    const dataStandings = tableStandingsHelper.getDataForTable()

    tableState().$patch({
      competitionText: modes.isTrainingMode() ?
        vm.$t('level').replace('%s', trainingManager.level) :
        `tableText${modes.mode}`,
      dataTable: dataStandings,
      resultText: modes.isTournament() || modes.isDailyLeague() ? 'provisionalResults' : 'finalStandings',
      showTable: true,
      activeState: true
    })

    startPhaseState().$reset()

  })

  // Listener for game start final training phase
  window.addEventListener(CustomEvents.startFinalTrainingPhase, () => {

    trainingResultsState().showResults = true

    audioManager.stopAllAudio()
    audioManager.play(AudioNames.audienceNoise, undefined, undefined, 1)

  })

  // Listener pre to, aby sme vedeli, ze su vsetky konecne requesty zbehnute
  window.addEventListener(CustomEvents.allRequestDoneOnEnd, () => {

    if (modes.isTournament() && Number(import.meta.env.VITE_APP_LOCAL) === 1) return

    stateManager.afterAllRequestsDone()

  })

  /** Listener pre zobrazenie loading circle po kliknuti na final redirect */
  window.addEventListener(CustomEvents.finalRedirectDone, () => {

    loadingCircleState().isActive = true

  })

}
